/* eslint-disable @typescript-eslint/no-explicit-any */
import cs from 'classnames';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { GG_RECAPTCHA_SITE_V3, TOPUP_TABS } from './constants';
import GetTC from './GetTC';
import s from './styles.module.scss';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    grecaptcha?: any;
  }
}

const TopupPage = () => {
  const { tab } = queryString.parse(location.search) as {
    tab: string;
  };
  const router = useRouter();

  const [key, setKey] = useState<string>(TOPUP_TABS.Faucet.path);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (tab) {
      setKey(tab);
    }
  }, [tab]);

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(GG_RECAPTCHA_SITE_V3, { action: 'homepage' })
        .then((token: string) => {
          onVerify(token);
        });
    });
  };

  const addCapcha = () => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${GG_RECAPTCHA_SITE_V3}`;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
    return () => {
      const script = document.createElement('script');
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    addCapcha();
    const intervalID = setInterval(addCapcha, 100 * 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <div className={cs(s.container)}>
      <GetTC reCaptcha={token} />
    </div>
  );
};

export default TopupPage;
