import MainLayout from '@/layouts/MainLayout';
import TopupPage from '@/modules/TopupPage';
import { GetServerSidePropsContext, NextPage } from 'next';

interface IPageProps {
  header?: string;
}

const Topup: NextPage<IPageProps> = ({ header }) => {
  return (
    <MainLayout
      isWallet={true}
      isLight={false}
      hideFooter
      hideHeader={header === 'hide'}
    >
      <TopupPage />
    </MainLayout>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { query } = context;
  const { header } = query as { header?: string };

  return {
    props: {
      seoInfo: {
        title: 'Alpha - Topup',
      },
      header: header || '',
    },
  };
}

export default Topup;
